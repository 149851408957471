import { useState, useEffect } from 'react';

export const useGreeting = () => {
    const [greeting, setGreeting] = useState("");

    const updateGreeting = () => {
        const hour = new Date().getHours();
        
        if (hour >= 0 && hour < 6) return "Boa Madrugada";
        if (hour >= 6 && hour < 12) return "Bom Dia";
        if (hour >= 12 && hour < 18) return "Boa Tarde";
        return "Boa Noite";
    };

    useEffect(() => {
        const setNewGreeting = () => setGreeting(updateGreeting());
        
        // atualiza inicial
        setNewGreeting();

        // calcula próxima hora de mudança
        const now = new Date();
        const nextHour = new Date();
        nextHour.setHours(now.getHours() + 1, 0, 0, 0);
        
        // configura timer para próxima atualização
        const timer = setTimeout(setNewGreeting, nextHour - now);
        return () => clearTimeout(timer);
    }, []);

    return greeting;
};