import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

// Largura do drawer definida no layout principal
const drawerWidth = 280;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // Remover estilos de scrollbar
    '& ::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',  // IE and Edge
    'scrollbar-width': 'none',  // Firefox
  },
  content: {
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',
    marginLeft: theme.spacing(7),
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    display: 'flex', // Adicionado
    flexDirection: 'column', // Adicionado
  },
  contentShift: {
    marginLeft: drawerWidth,
  }
}));

const DocsPage = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const drawerOpen = localStorage.getItem('drawerOpen') === 'true';

  return (
    <div className={clsx(classes.root)}>
      <div className={clsx(classes.content, {
        [classes.contentShift]: drawerOpen,
      })}>
        {loading && (
          <div style={{ 
            padding: '20px',
            textAlign: 'center'
          }}>
            Carregando documentação...
          </div>
        )}
        <iframe
          src="https://app.wasap.com.br/guia-do-usuario/"
          title="Documentação Wasap"
          onLoad={() => setLoading(false)}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            flexGrow: 1, // Adicionado
          }}
        />
      </div>
    </div>
  );
};

export default DocsPage;
