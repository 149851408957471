import React, { useState, useEffect } from "react";
import openSocket from "../../services/socket-io";

import { 
    Container,
    makeStyles,
    Paper,
    TextField,
    Typography,
    Grid
} from "@material-ui/core";

import CopyToClipboard from "../../components/CopyToClipboard";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2),
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3),
        display: "flex",
        alignItems: "center", 
        borderRadius: 13,
        boxShadow: "0 2px 15px rgba(0,0,0,0.1)",
        transition: "transform 0.3s ease",
        "&:hover": {
            transform: "scale(1.01)",
        }
    },

    container: {
        width: "100%",
        maxWidth: 700,
        marginTop: theme.spacing(2),
    },

    textField: {
        "& .MuiOutlinedInput-root": {
            borderRadius: 13,
        }
    }
}));

const ApiKey = () => {
    const classes = useStyles();
    const [settings, setSettings] = useState([]);

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const { data } = await api.get("/settings");
                setSettings(data);
            } catch (err) {
                toastError(err);
            }
        };
        fetchSession();
    }, []);

    useEffect(() => {
        const socket = openSocket(process.env.REACT_APP_BACKEND_URL);
        
        socket.on("settings", data => {
            if (data.action === "update") {
                setSettings(prevState => {
                    const aux = [...prevState];
                    const settingIndex = aux.findIndex(s => s.key === data.setting.key);
                    aux[settingIndex].value = data.setting.value;
                    return aux;
                });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const getSettingValue = key => {
        const { value } = settings.find(s => s.key === key);
        return value;
    };

    return (
        <div className={classes.root}>
            <MainHeader>
                <Title>{i18n.t("mainDrawer.listItems.apikey")}</Title>
            </MainHeader>

            <Container className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom color="primary">
                            {i18n.t("mainDrawer.listItems.token")}
                        </Typography>
                        <Paper className={classes.paper}>
                            <TextField
                                id="api-token-setting"
                                label="Api Key"
                                variant="outlined"
                                fullWidth
                                className={classes.textField}
                                value={settings && settings.length > 0 && getSettingValue("userApiToken")}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <CopyToClipboard 
                                content={settings && settings.length > 0 && getSettingValue("userApiToken")} 
                                color="primary"
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default ApiKey;